import React, { Component } from 'react';
//import Masonry from 'react-masonry-component';
import './App.css';
import portfolioData from './portfolio.json';

import OnImagesLoaded from 'react-on-images-loaded';
import Isotope from 'isotope-layout';

import $ from "jquery";

import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import 'slick-carousel'

//const masonryOptions = { };
//const imagesLoadedOptions = { background: false }

class Header extends React.Component {
  render() {
    return (
      <header>
        <div className="content">
          <h1>Kevin Considine</h1>
          <h2>Software Developer</h2>
          <div className="section-buttons menu">
            <button className={this.props.webTabActive ? 'button active': 'button'} data-tab="web" onClick={this.props.selectTab}>WEB</button> 
            <button className={this.props.hobbyTabActive ? 'button active': 'button'}  data-tab="hobby" onClick={this.props.selectTab}>HOBBY</button> 
          </div>
          <div className="social">
            <a href="https://github.com/kconsidine" target="_blank" rel="noreferrer">
              <img src="dist/img/github.svg" alt="Kevin Considine on GitHub" />
            </a>
            <a href="https://www.linkedin.com/in/kevin-considine-63080b13/" target="_blank" rel="noreferrer">
              <img src="dist/img/linkedin.svg" alt="Kevin Considine on LinkedIn" />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

class WebPortfolio extends React.Component {
  render() {
    return(
      <div id="web" className={this.props.webTabActive ? 'page-tab active-tab': 'page-tab'}>
        <h2>Recent Web Projects</h2>
        <WorkGallery />
      </div>
    );
  }
}

class PortfolioSorting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterKey: "*"
    };
  }

  handleFilterButtonClick = (event) => {
    let filtered = event.target.getAttribute('data-filter');
    this.setState({ filterKey: filtered });

    this.props.filter(filtered);
  }

  render() {
    return(
      <div className="portfolio-menu portfolio-left-menu text-center mb-50">
        <button className={(this.state.filterKey === "*") ? "sort active" : "sort"} data-filter="*" onClick={this.handleFilterButtonClick}>ALL</button>
        <button className={(this.state.filterKey === "web-motion") ? "sort active" : "sort"} data-filter="web-motion" onClick={this.handleFilterButtonClick}>Web Animation</button>
        <button className={(this.state.filterKey === "front-end") ? "sort active" : "sort"} data-filter="front-end" onClick={this.handleFilterButtonClick}>Front-End</button>
        <button className={(this.state.filterKey === "back-end") ? "sort active" : "sort"} data-filter="back-end" onClick={this.handleFilterButtonClick}>Back-End</button>
        <button className={(this.state.filterKey === "shopify") ? "sort active" : "sort"} data-filter="shopify" onClick={this.handleFilterButtonClick}>Shopify</button> 
      </div>
    );
  }
}

class WorkGallery extends React.Component {
  constructor(props) {
    super(props);
    this.isotope = React.createRef();
    this.state = {
      filterKey: "*"
    };
  }
  
  runAfterImagesLoaded = () => {
    this.isotope.current = new Isotope('.work-grid', {
      itemSelector: '.work-object',
      percentPosition: true,
      masonry: {
          columnWidth: '.work-object',
      }
    })
  }

  // runTimeoutFunction = () => {
  //   // Timeout
  // }

  handleFilterKeyChange = (filter) =>  {
    //this.setState({ filterKey: filter });

    filter === '*'
      ? this.isotope.current.arrange({filter: `*`})
      : this.isotope.current.arrange({filter: `.${filter}`})
  }

  render() {
    return (
      <>
        <PortfolioSorting filter={this.handleFilterKeyChange} />
        <div id="work-grid">
          <div className="work-grid">
            <OnImagesLoaded
              onLoaded={this.runAfterImagesLoaded}
              onTimeout={this.runTimeoutFunction}
              timeout={10000}
            >
                {portfolioData.map(item => <ProjectBlock key={item.id} project={item} />)}
            </OnImagesLoaded>
          </div>
          <div className="clearfix"></div>
        </div>
      </>
    )
  }

  /* // React Masonry Grid
  handleLayoutComplete() { }

  componentDidMount() {
    this.masonry.on('layoutComplete', this.handleLayoutComplete);
  }

  componentWillUnmount() {
    this.masonry.off('layoutComplete', this.handleLayoutComplete);
  }
  
  render() {
    return(
      <Masonry
          className={'work-grid'}
          elementType={'div'}
          options={masonryOptions}
          imagesLoadedOptions={imagesLoadedOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
          ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
      >
          {portfolioData.map(item => <ProjectBlock key={item.id} project={item} />)}
      </Masonry>
    );
  }
  */
}


class ProjectBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileModal: false
    };
  }

  project = this.props.project;
  //projectTitle = this.project.project.replace("<br />", " ").replace(/<\/?[^>]+(>|$)/g, "");

  tags = () => {
    let projectTags = this.project.tags;
    let tagString = "work-object";

    if (projectTags.length) {
      for (var i = 0; i < projectTags.length; i++) {
        tagString += " " + projectTags[i];
      }
    }

    return tagString;
  }

  projectURL = () => {
    if (this.project.link) {
      return (<a target="_blank" href={this.project.link.url} dangerouslySetInnerHTML={ { __html: this.project.link.url_label } }></a>);
    } else {
      return (<></>);
    }
  }

  highlights = () => {
    let projectHighlights = this.project.details.highlights;

    if (projectHighlights.length) {
      return (
        <ul>
          {projectHighlights.map((hightlight, i) => {
            return (<li key={i} dangerouslySetInnerHTML={ { __html: hightlight } }></li>); })}
        </ul>
      );
    }
  }

  footer = () => {
    if (this.project.media.footer) {
      return (<img src={this.project.media.footer.img} alt={this.project.media.footer.alt} />);
    } else { 
      return (<></>);
    }
  }

  toggleMobileDetails= () => {
    this.setState({ mobileModal: !this.state.mobileModal });
  }

  render() {
    return (
      <div className={this.tags()}>
        <span className="view" onClick={this.toggleMobileDetails}>
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="eye" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-eye fa-w-18 fa-9x"><path fill="#fff" d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>
        </span>
        <div className={this.state.mobileModal ? 'work-item mobile-visible': 'work-item'}>
          {this.project.media.video
            ? <VideoMedia mp4={this.project.media.video.mp4} webm={this.project.media.video.webm} />
            : <ImageMedia src={this.project.media.img.url} alt={this.project.media.img.alt} />
          }
          <div className="work-details">
              <div className="details">
                <div className="close" onClick={this.toggleMobileDetails}></div>
                <h2 dangerouslySetInnerHTML={ { __html: this.project.project } }></h2>
                <h3>{this.project.year}</h3>
                {this.projectURL()}
                <div className="highlights">
                    <p dangerouslySetInnerHTML={ { __html: this.project.details.primary } }></p>
                    {this.highlights()}
                </div>
              </div>
              {this.footer()}
          </div>
        </div>
      </div>
    );
  }
}

class HobbyPortfolio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slidersLoaded: false
    };
  }

  handleClick = (event) => {
    let itemName = event.target.getAttribute('data-project');

    let clickedSection = document.getElementById(itemName);
    clickedSection.scrollIntoView({ behavior: 'smooth' });
  }

  componentDidUpdate() {
    if (this.props.hobbyTabActive && this.state.slidersLoaded === false) {
      this.buildSliders()
    }
  }

  buildSliders() {
    $('.nova-cup-slider').slick({
       lazyLoad: 'ondemand',
       infinite: true,
       slidesToShow: 1,
       slidesToScroll: 1,
       arrows: true,
       fade: true,
       asNavFor: '.nova-cup-slider-nav',
       autoplay: true,
       autoplaySpeed: 3000
    });

    $('.nova-cup-slider-nav').slick({
       lazyLoad: 'ondemand',
       infinite: true,
       slidesToShow: 5,
       slidesToScroll: 1,
       asNavFor: '.nova-cup-slider',
       dots: false,
       centerMode: true,
       focusOnSelect: true,
       arrows: false
    });


    $('.boat-slider').slick({
       lazyLoad: 'ondemand',
       infinite: true,
       slidesToShow: 1,
       slidesToScroll: 1,
       asNavFor: '.boat-slider-thumbs'
    });

    $('.boat-slider-thumbs').slick({
       lazyLoad: 'ondemand',
       infinite: true,
       slidesToShow: 10,
       slidesToScroll: 1,
       asNavFor: '.boat-slider',
       centerMode: false,
       focusOnSelect: true,
       arrows: false,
       responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        }
       ]
    });

    this.setState({ slidersLoaded: true });
  }
  
  render() {
    return (
      <div id="hobby" className={this.props.hobbyTabActive ? 'page-tab active-tab': 'page-tab'}>
        <h2>Recent Hobby Projects</h2>
        <div className="portfolio-menu portfolio-left-menu text-center mb-50">
          <button className="hobby-scroll" data-project="arcade-pong" onClick={this.handleClick}>ARCADE PONG</button>
          <button className="hobby-scroll" data-project="boat" onClick={this.handleClick}>BOAT</button>
        </div>
        <div className="nova-project-wrapper">
          <div className="nova-cup-grid">
            <div className="cup-grid" id="arcade-pong">
              <div className="grid-left">
                <NovaCupScene loadModel={this.props.hobbyTabActive} />

                <div id="nova-cup">
                  <div className="nova-cup-slider">
                    <div><img data-lazy="dist/img/slider/slide-1.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-2.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-3.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-4.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-5.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-6.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-7.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-8.jpg" alt="" /></div>
                  </div>
                  <div className="nova-cup-slider-nav">
                    <div><img data-lazy="dist/img/slider/slide-1-thumb.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-2-thumb.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-3-thumb.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-4-thumb.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-5-thumb.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-6-thumb.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-7-thumb.jpg" alt="" /></div>
                    <div><img data-lazy="dist/img/slider/slide-8-thumb.jpg" alt="" /></div>
                  </div>
                </div>
              </div>
              <div className="grid-right">
                <div id="nova-cup-video">
                  <video autoPlay="autoPlay" playsInline="playsInline" muted="muted" loop="loop">
                    <source src="dist/video/novacup.mp4" type="video/mp4" />
                    <source src="dist/video/novacup.webm" type="video/webm" />
                  </video>
                </div>
              </div>
            </div>
            <div className="project-details">
              <h2>Interactive Arcade "Beer" Pong Game</h2>
              <p>Interactive light and infrared based "beer" pong style game, pong balls fall through hollow cups and break an infrared beam inside each cup, the balls end up on the opponent’s side awaiting their turn. Sonar is used to detect player distance to the table, and a visible 20mw line lens laser mounted inside the table on a servo draws a visible line on the ground as a specific point equidistant to the expected parameter from the sonar used to dictate to the player where to stand for their turn, the sonar triggers an event in the game should the player step passed that laser line.</p>
              <p>Built on a Raspberry Pi server running NodeJS, communicating with the sensors &amp; LEDs on various arduinos using websocket, mini touch screen connected directly to the pi running the JS front-end of the game, and all button inputs and events are sent as websocket events to the pi.</p>
            </div>
          </div>
        </div>
        <div id="boat">
          <div className="boat-slider">
            <div>
            <video autoPlay="autoPlay" playsInline="playsInline" muted="muted" loop="loop">
                <source src="dist/video/boatsplash.mp4" type="video/mp4" />
                <source src="dist/video/boatsplash.webm" type="video/webm" />
              </video>
            </div>
            <div><img src="dist/img/boat/slides/0.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/1.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/2.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/3.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/4.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/5.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/6.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/7.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/8.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/9.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/10.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/11.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/12.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/13.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/14.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/15.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/16.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/17.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/18.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/22.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/19.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/20.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/slides/21.jpg" alt=""  /></div>
          </div>
          <div className="boat-slider-thumbs">
            <div><img data-lazy="dist/img/boat/thumbs/video.jpg" alt="" /></div>
            <div><img data-lazy="dist/img/boat/thumbs/0.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/1.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/2.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/3.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/4.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/5.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/6.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/7.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/8.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/9.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/10.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/11.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/12.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/13.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/14.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/15.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/16.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/17.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/18.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/22.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/19.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/20.jpg" alt=""  /></div>
            <div><img data-lazy="dist/img/boat/thumbs/21.jpg" alt=""  /></div>
          </div>
          <div className="project-details">
            <h2>Boat Restoration 2016 - 2019</h2>
            <p>Full rebuild of a destroyed 1987 bow rider boat, keeping only the fiberglass hull and windshield. Replaced stringers, deck, transom, electronics, seats, engine, etc. With the exception of help with heavy lifting and tedious sanding, I took the project on alone with the intent of learning new skills, and having a cool boat at the end of the project.</p>
            <p>Complete with a custom modern electrical system including a NMEA2K network with engine data/diagnostics, DC circuit breaker, individual appliance fuses, properly gauged marine wiring per USCG specifications, underwater lighting, accent lighting, USB cell phone chargers, speaker system, VHF radio, and depth sounder.</p>
            <p>As of 2021 the boat has over 100hrs on the engine and has successfully navigated numerous Hudson River ventures including three 120 mile trips to lower Manhattan, New York City as well as trips to Hudson, New York. Much of the New York City area waters are pretty serious for a boat of this size, but there have been no issues.</p>
          </div>
        </div>
      </div>
    );
  }
}

class NovaCupScene extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modelLoaded: false
    };
  }

  componentDidUpdate() {
    if (this.props.loadModel && this.state.modelLoaded === false) {
      this.buildModel();
    }
  }

  componentDidMount() {
    /*
    if (this.props.loadModel) {
      this.buildModel();
    }
    */
  }

  buildModel() {
    //var modelLoaded = false;
    let container = $("#nova-cup-model");
    //var overlay   = $('.model-overlay');
    let camera, scene, renderer;

    camera = new THREE.PerspectiveCamera( 40, container.width() / container.height(), 0.01, 10 );
    camera.position.z = 5;

    scene = new THREE.Scene();

    let ambientLight = new THREE.AmbientLight( 0xcccccc, 0.6 );
    scene.add( ambientLight );

    let pointLight = new THREE.PointLight( 0xffffff, 0.75 );
    camera.add( pointLight );
    scene.add( camera );

    function onProgress( xhr ) {
        if ( xhr.lengthComputable ) {
          const percentComplete = xhr.loaded / xhr.total * 100;
          let percentVal = Math.round( percentComplete, 2 ),
              overlay    = $('.model-overlay');

          if (percentVal < 100) {
              if (!overlay.is(":visible")) {
                overlay.css('display', 'flex');
              }

              $(".model-overlay > .loading").text('MODEL LOADING: ' + Math.round( percentComplete, 2 ) + '%');
          } else {
              overlay.css('display', 'none');
          }
        }
    }

    function onError() {
      $('.model-overlay').css('display', 'flex');
        $(".model-overlay > .loading").text('ERROR LOADING MODEL');
    }

    function onWindowResize() {
      camera.aspect = container.width() / container.height();
      camera.updateProjectionMatrix();
      renderer.setSize( container.width(), container.height() );
    }

    let loader = new OBJLoader();
    loader.load( process.env.PUBLIC_URL+'/dist/models/novacup_open.obj', function ( obj ) {
      let box = new THREE.Box3().setFromObject( obj );
      let center = new THREE.Vector3();
      box.getCenter( center );
      obj.position.sub( center );
      obj.name = "nova_open";
      scene.add( obj );
    }, onProgress, onError );

    renderer = new THREE.WebGLRenderer( { alpha: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( container.width(), container.height() );
    renderer.setClearColor( 0x000000, 0 );
    container.append( renderer.domElement );

    let controls = new OrbitControls( camera, renderer.domElement );
    controls.minDistance = 2;
    controls.maxDistance = 6;
    camera.position.set(-3,1.5,2.5);
    camera.zoom = 2;

    controls.update();

    camera.aspect = container.width() / container.height();
    camera.updateProjectionMatrix();

    window.addEventListener( 'resize', onWindowResize, false );

    //modelLoaded = true;

    function animate() {
      requestAnimationFrame( animate );
      render();
    }

    function render() {
      renderer.render( scene, camera );
    }  

    animate();

    this.setState({ modelLoaded: true });
  }

  render() {
    return (
      <div id="nova-cup-model">
        <div className="model-overlay"><div className="loading" /></div>
        <div className="click-to-interact">
          <span className="interact-mobile">TOUCH TO INTERACT, PINCH TO ZOOM</span>
          <span className="interact-desktop">CLICK TO INTERACT, WHEEL TO ZOOM</span>
        </div>
      </div>
    )}
}

class VideoMedia extends Component {
  render() {
    return (
      <video autoPlay="autoplay" playsInline="playsinline" muted="muted" loop="loop">
        {this.props.webm && <source type="video/webm" src={this.props.webm} />}
        {this.props.mp4 && <source type="video/mp4" src={this.props.mp4} />}
      </video>
    )
  }
}

class ImageMedia extends Component {
  render() {
    return (
      <img src={this.props.src} alt={this.props.alt} />
    )
  }
}

function Footer() {
  return (
    <footer>
        <p>&ldquo;Luck is where preparation meets opportunity&rdquo;</p>
    </footer>
  );
}

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      web: true,
      hobby: false
    };
    this.selectTab = this.selectTab.bind(this);
  }

  componentDidMount() {
    console.log("Checking the console!? Nice... - Fired on react app componentDidMount - Kevin")
  }

  componentDidUpdate() {
    // Triggering a resize event (even w/o resize) tells Isotope to recalculate the Masonry grid.
    window.dispatchEvent(new Event('resize'));
  }

  selectTab(event) {
    this.setState({ web: !this.state.web });
    this.setState({ hobby: !this.state.hobby });
  }

  render() {
    return ( 
      <div>
        <Header webTabActive={this.state.web} hobbyTabActive={this.state.hobby} selectTab={this.selectTab} />
        <div className="portfolio">
          <WebPortfolio webTabActive={this.state.web} />
          <HobbyPortfolio hobbyTabActive={this.state.hobby} />
        </div>
        <Footer />
      </div>
    )
  }
}
